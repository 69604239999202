import React from "react"
import styled from "styled-components"
import Map from "./Map"
import Search from "./Search"
import colors from "../../styles/colors"

const MapBox = styled.div`
  height: 481px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  @media (min-width: 991px) {
    height: 800px;
  }
`

const Overlay = styled.div`
  height: 144px;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0),
    rgba(0, 9, 20, 1)
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 9, 20, 1)
  );
  background: linear-gradient(to top, rgba(2, 9, 19, 0), rgba(2, 9, 19, 1));
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: ${props => props.bottom && "0"};
  transform: ${props => props.bottom && "rotate(180deg)"};
  pointer-events: none;
  @media (min-width: 991px) {
    height: 240px;
  }
`

const SearchContainer = styled.div`
  padding: 0 30px 32px 36px;
  background-color: ${colors.darkNavy};
  position: relative;
  @media (min-width: 991px) {
    display: none;
  }
`

const Border = styled.div`
  position: absolute;
  top: 8px;
  left: 40px;
  right: 0;
  background-color: transparent;
  border-bottom: 7px solid ${colors.mint};
  border-right: 7px solid ${colors.mint};
  z-index: 2;
  height: calc(100% - 40px);
  width: calc(100% - 70px);
`

class MapBlock extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      apiReady: false,
      mapInstance: null,
      googlemaps: null,
      place: null,
      nearest: undefined,
      radius: null,
    }
  }

  apiHasLoaded = (map, googlemaps) => {
      this.setState({
        apiReady: true,
        map: map,
        googlemaps: googlemaps
      });
  }

  addPlace = (place, radius) => {
    this.setState({ place: place, radius: radius })
  }

  updateNearest = (store) => {
    this.setState({nearest: store})
  }

  render() {
    const { place, apiReady, mapInstance, googlemaps, nearest } = this.state
    const {locations, toggledMarker, setToggledMarker, center, setCenter} = this.props
    const {addPlace, updateNearest, apiHasLoaded} = this;
    const searchProps = {map: mapInstance, googlemaps, addPlace, nearest, setToggledMarker, center, setCenter}
    return (
      <>
        {apiReady && (
          <SearchContainer>
            <Search mobileonly={"true"} {...searchProps} />
            <Border />
          </SearchContainer>
        )}
        <MapBox>
          {apiReady && <Search desktoponly={"true"} {...searchProps}/>}
          <Overlay />
          <Map apiHasLoaded={apiHasLoaded} place={place} updateNearest={updateNearest} nearest={nearest} radius={this.state.radius} locations={locations} toggledMarker={toggledMarker} setToggledMarker={setToggledMarker} newCenter={center} setCenter={setCenter} />
          <Overlay bottom={"true"} />
        </MapBox>
      </>
    )
  }
}

export default MapBlock
