import React, { Component } from "react"
import Layout from "../components/core/Layout"
import SEO from "../components/core/Seo"
import get from "lodash/get"
import Hero from "../components/shipmates/Hero"
import ContentBlock from "../components/shipmates/Content"
import MapBlock from "../components/shipmates/MapBlock"
import PrivateRoute from "../router/PrivateRoute"

class ShimatesPage extends Component {
  constructor(props) {
    super(props)
    this.toggleMarker = this.toggleMarker.bind(this)
    this.setCenter = this.setCenter.bind(this)
    this.state = {
      toggledMarker: null,
      center: undefined,
    }
  }

  toggleMarker(key) {
    this.setState({ toggledMarker: key })
  }

  setCenter(location) {
    this.setState({ center: location })
  }

  render() {
    const path = this.props.location.pathname
    const locations = get(this, "props.data.locations.nodes")
    const page = get(this, "props.data.page")
    const { toggledMarker } = this.state
    const { toggleMarker } = this
    locations.sort((a, b) => {
      return (a.order === null)-(b.order === null) || +(a.order > b.order)||-(a.order < b.order);
    })

    return (
      <Layout>
        <SEO title={page.metaTitle} description={page.metaDescription} fullTitle={false} path={path} />
        <Hero />
        <ContentBlock
          locations={locations}
          toggledMarker={toggledMarker}
          setToggledMarker={toggleMarker}
          setCenter={this.setCenter}
        />
        <MapBlock
          locations={locations}
          toggledMarker={toggledMarker}
          setToggledMarker={toggleMarker}
          setCenter={this.setCenter}
          center={this.state.center}
        />
      </Layout>
    )
  }
}

function shipmates(props) {
  return <PrivateRoute component={ShimatesPage} {...props} />
}

export default shipmates

export const pageQuery = graphql`
  query ShipmatesPageQuery {
    page: contentfulLandingPage(slug: { eq: "shipmates" }) {
      id
      metaTitle
      metaDescription
    }
    locations: allContentfulLocation {
      nodes {
        id
        name
        address1
        address2
        city
        emailAddress
        telephone
        image {
          fluid(quality: 90, maxWidth: 620) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        openingHourSun
        openingHourWeek
        order
        location {
          lat
          lon
        }
      }
    }
  }
`
