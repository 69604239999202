import React, { Component } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import Img from "gatsby-image"
import LeftArrow from "../../resources/images/shipmates/arrow-left.svg"
import RightArrow from "../../resources/images/shipmates/arrow-right.svg"
import fonts from "../../styles/fonts"
import OnClickButton from "../core/OnClickButton"
import colors from "../../styles/colors"

const SliderContainer = styled.div`
    width: 100%;
    position: relative;
`

const Slide = styled.div`
    outline: none;
    border: 0;
    margin: auto;
    
`

const StyledSlider = styled(Slider)`
  .slick-track,
  .slick-list,
  .slick-slide{
    height: 100%;
    width: auto;
    padding-bottom: 60px;
    outline: none;
  }

  @media(min-width: 992px){
      padding: 0 60px;
  }
`

const CarouselController = styled.img`
    cursor: pointer;
    width: 40px;
    height: 6px;
    border: none;
`

const ImageContainer = styled.div`
        max-height: 411px;
        max-width: 322px;
`

const SlideImg = styled(Img)`
    height: auto;
    width: auto;
    max-height: 100vw;
    min-height: 100vw;
    margin: 0 auto;
    transition: .1s;
    object-fit: cover;
    @media(min-width: 992px){
        margin: 5px;
        opacity: ${props => props.isVisible ? '1' : '.5'};
        height: 411px;
        max-width: 322px;
        max-height: unset;
        min-height: unset;
        margin: auto;
    }
`

const CityName = styled.div`
    font-size: 12px;
    letter-spacing: 2.8px;
    text-align: center;
    line-height: 14px;
    text-transform: uppercase;
    margin-top: 48px;
    margin-bottom: 15px;
    ${fonts.gilroyRegular};
    color: ${colors.white};
`

const RestaurantName = styled.div`
    font-size: 20px;
    color: ${colors.white};
    letter-spacing: 1.25px;
    line-height: 24px;
    text-align: center;
    ${fonts.swissBlackExtended};
    text-transform: uppercase;
    margin-bottom: 32px;
`

const Details = styled.div`
    text-align: center;
    @media(min-width: 992px){
      display: ${props => props.isVisible ? "block" : "none"};
    }
`

const CardContainer = styled.div`
    opacity: ${props => props.current === true ? "1" : "0.5"};
    padding: 0 5px;
    @media(min-width: 992px){
        opacity: 1;
        padding-left: ${props => props.isFirst ? '0' : '5px'};
        padding-right: ${props => props.isLast ? '0' : '5px'};
    }
`

const CarouselControllers = styled.div`
    display: none;
    @media(min-width: 992px){
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        z-index: 2;
        top: 25%;
    }
`

const Previous = styled.span`
     z-index: 1;
     cursor: pointer;
     margin-left: 40px;
`

const Next = styled.span`
     cursor: pointer;
     z-index: 1;
     margin-right: 40px;
`

class ShipmatesCarousel extends Component {
  state = {
    slideIndex: 0,
    shownDirection: 1,
  }

  changeSlide(diff) {
    const slideIndex = this.state.slideIndex + diff

    this.slider.slickGoTo(slideIndex)
  }

  showDirections(index) {
    this.setState({
      shownDirection: index,
    })
  }

  handleClick = (e, location) => {
    const {setCenter, setToggledMarker} = this.props;
    setToggledMarker(location.id)
    setCenter(location)
    this.scrollTo(e, "map")
  }

  scrollTo = (e, sectionId) => {
    if (typeof window !== "undefined") {
      e.preventDefault();
      let yOffset = -50;
      let element = document.getElementById(sectionId);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: "smooth"})
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.slideIndex !== this.state.slideIndex) {
      this.showDirections(this.state.slideIndex)
    }
  }

  componentDidMount() {
    if (this.state.slideIndex === 0) {
      this.showDirections(this.state.slideIndex)
    }

  }

  render() {
    const { locations: slides } = this.props
    const { slideIndex } = this.state

    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      fade: false,
      swipe: true,
      swipeToSlide: true,
      draggable: true,
      speed: 500,
      slidesToShow: slides.length < 4 ? slides.length : 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      initialSlide: 0,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      responsive: [
        {
          breakpoint: "992px",
          settings: {
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            centerMode: true,
          },
        },
      ],
    }

    return (
      <SliderContainer>
        <StyledSlider ref={slider => (this.slider = slider)}  {...settings}>
          {slides.map((slide, index) => {
            return (
              <Slide key={"image-" + index} onMouseEnter={() => this.showDirections(index)}>
                <CardContainer current={slideIndex === index} isFirst={index === 0}
                               isLast={index === (slides.length - 1)}>
                  <SlideImg fluid={slide.image.fluid} isVisible={this.state.shownDirection === index}/>
                  <Details isVisible={this.state.shownDirection === index}>
                    <CityName>{slide.city}</CityName>
                    <RestaurantName>{slide.name}</RestaurantName>
                    <OnClickButton onClick={(e) => this.handleClick(e, slide)}> Directions </OnClickButton>
                  </Details>
                </CardContainer>
              </Slide>
            )
          })}
        </StyledSlider>
        <CarouselControllers>
          <Previous onClick={() => this.changeSlide(-1)}><CarouselController src={LeftArrow}/></Previous>
          <Next onClick={() => this.changeSlide(1)}><CarouselController src={RightArrow}/></Next>
        </CarouselControllers>
      </SliderContainer>
    )
  }
}

export default ShipmatesCarousel
