import React, { Component } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"

const Block = styled.div`
  background: ${colors.white};
  z-index: 3;
  width: auto;
  padding: 52px 31px 47px 34px;
  display: ${props => props.desktoponly && "none"};
  display: ${props => props.mobileonly && "block"};
  @media (min-width: 991px) {
    position: absolute;
    top: 101px;
    max-width: 330px;
    left: 79px;
    padding: 52px 41px 47px;
    display: ${props => props.desktoponly && "block"};
    display: ${props => props.mobileonly && "none"};
  }
  @media (min-width: 1400px) {
    position: absolute;
    top: 204px;
    max-width: 330px;
    left: 158px;
    padding: 52px 41px 47px;
  }
`

const SearchBlock = styled.div`
  z-index: 3;
  position: relative;
`

const Title = styled.div`
  ${fonts.swissBlackExtended};
  font-size: 16px;
  color: ${colors.navy};
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 28px;
`

const Label = styled.label`
  ${fonts.gilroyRegular};
  text-transform: uppercase;
  font-size: 12px;
  color: ${colors.navy};
  letter-spacing: 2.77px;
  text-align: center;
  display: block;
  padding-bottom: 16px;
`

const Input = styled.input`
  ${fonts.gilroyRegular};
  display: block;
  width: 100%;
  height: 44px;
  border-radius: 0;
  border: 1.5px solid ${colors.borderGrey};
  margin-bottom: 22px;
  outline: none;
  padding-left: 19px;
  box-sizing: border-box;
`

const Button = styled.button`
  ${fonts.gilroyBold};
  text-transform: uppercase;
  font-size: 12px;
  color: ${colors.navy};
  letter-spacing: 2.77px;
  text-align: center;
  border: 1px solid ${colors.mint};
  background-color: ${colors.white};
  width: calc(100% - 5px);
  height: 44px;
  cursor: pointer;
  transition: 0.3s;
  outline: none;
  &:hover {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    background: ${colors.mint};
    border: 1px solid ${colors.mint};
    color: ${colors.navy};
    transition: 0.3s;
  }
`

const BorderStyled = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  right: 0;
  background-color: transparent;
  border-bottom: 5px solid ${colors.mint};
  border-right: 5px solid ${colors.mint};
  width: calc(100% - 10px);
  z-index: 0;
  height: 39px;
  display: ${props => (props.isVisible ? "block" : "none")};
`

const ButtonBox = styled.div`
  position: relative;
  cursor: pointer;
`

const Border = styled.div`
  @media (min-width: 991px) {
    position: absolute;
    top: 8px;
    left: 10px;
    right: 0;
    background-color: transparent;
    border-bottom: 8px solid ${colors.mint};
    border-right: 8px solid ${colors.mint};
    width: calc(100% - 10px);
    z-index: 0;
    height: calc(100% - 8px);
  }
`
const Miles = styled.div`
  ${fonts.gilroyRegular};
  color: ${colors.grey};
  font-size: 15px;
  line-height: 21px;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
`

const RadiusContainer = styled.div`
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

`

class Search extends Component {
  constructor(props) {
    super(props)
    this.clearSearchBox = this.clearSearchBox.bind(this)
    this.state = {
      isBorder: false,
      address: null,
      radius: 0,
    }
  }


  componentDidMount({ googlemaps } = this.props) {
    const options = {
      types: ['geocode'],
      componentRestrictions: {country: 'gb'},
    };
    this.autoComplete = new googlemaps.places.Autocomplete(
      this.searchInput,
      options,
    );
    this.autoComplete.addListener('place_changed', this.onPlaceChanged);
    if (typeof window !== "undefined") {
      this.enableEnterKey(this.searchInput)
    }
    this.toggleHover()
  }

  componentWillUnmount({ googlemaps } = this.props) {
    googlemaps.event.clearInstanceListeners(this.searchInput)
  }

  onPlaceChanged = () => {
    const place = this.autoComplete.getPlace();
    this.setState({address: place})
    this.searchInput.blur();
  };

  clearSearchBox() {
    this.searchInput.value = ""
  }

  toggleHover = () => {
    this.setState({
      isBorder: !this.state.isBorder,
    })
  }

  handleSubmit = () => {
    const { addPlace, setCenter, nearest, setToggledMarker } = this.props
    addPlace(this.state.address, this.state.radius)
    if (nearest) {
      setCenter(nearest)
      setToggledMarker(nearest.id)
    }
  }


  enableEnterKey = (input) =>  {
    const _addEventListener = input.addEventListener

    const addEventListenerWrapper = (type, listener) => {
      if (type === 'keydown') {
        const _listener = listener
        listener = (event) => {
          const suggestionSelected = document.getElementsByClassName('pac-item-selected').length
          if (event.key === 'Enter' && !suggestionSelected) {
            const e = new KeyboardEvent('keydown', { 
              key: 'ArrowDown', 
              code: 'ArrowDown', 
              keyCode: 40, 
            })
            _listener.apply(input, [e])
          }
          _listener.apply(input, [event])
        }
      }
      _addEventListener.apply(input, [type, listener])
    }

    input.addEventListener = addEventListenerWrapper
  }



  
  render() {
    const { desktoponly, mobileonly } = this.props

    return (
      <Block desktoponly={desktoponly} mobileonly={mobileonly}>
        <SearchBlock>
          <Title>Find one near you</Title>
          <Label>Search radius</Label>
          <RadiusContainer>
            <Input type="number" onChange={(e) => this.setState({radius: e.target.value})} />
            <Miles>Miles</Miles>
          </RadiusContainer>
          <Label>Postcode or address</Label>
          <Input
            ref={ref => {
              this.searchInput = ref
            }}
            type="text"
            onFocus={this.clearSearchBox}
          />
          <ButtonBox
            onMouseEnter={this.toggleHover}
            onMouseLeave={this.toggleHover}
            onClick={() => this.handleSubmit()}
          >
            <Button type="submit">Submit</Button>
            <BorderStyled isVisible={this.state.isBorder} />
          </ButtonBox>
        </SearchBlock>
        <Border />
      </Block>
    )
  }
}

export default Search
