import React from "react"
import styled from "styled-components"
import MarkerImg from "../../resources/images/shipmates/map-marker.svg"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"

const StyledMarker = styled.img`
    height: 16.8px;
    width: 8.4px;
    cursor: pointer;
    z-index: 9999;
    @media(min-width: 991px){
        height: 22px;
        width: 11px;
    }
`

const InfoBox = styled.div`
  position: absolute;
  background: ${colors.white};
  padding: 31px 30px 31px;
  height: auto;
  width: 172px;
  top: -250px;
  left: -111px;
  z-index: 1;
  @media(min-width: 991px){
      top: -255px;
  }
`

const Name = styled.div`
    ${fonts.swissBlackExtended};
    font-size: 13px;
    color: ${colors.navy};
    letter-spacing: 0.81px;
    text-transform: uppercase;
    padding-bottom: 15px;
`

const Text = styled.div`
    color: ${colors.navy};
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    ${fonts.gilroyRegular};
    a {
        color: ${colors.navy};
        text-decoration: none;
        font-size: 13px;
        line-height: 18px;
    }
`

const Day = styled(Text)`
    width: 70px;
`

const HoursBox = styled.div`
    padding-top: ${props => props.reducedPadding ? "6px" :"24px"};
`

const OpenHours = styled.div`
    display: flex;
    flex-direction: row;
`

const Square = styled.div`
    background-color: ${colors.white};
    height: 20px;
    width: 20px;
    transform: rotate(45deg);
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
`

const Distance = styled.div`
    color: ${colors.mint};
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    ${fonts.gilroyRegular};
    padding-top: 6px;

`

const MapMarker = ({isToggled, toggle, marker}) => {
    return <div
      onClick={() => toggle(isToggled ? null : marker.id)}>
      <StyledMarker alt={"Marker"} src={MarkerImg} />
      {
        isToggled &&
        <InfoBox>
          <Name>{marker.name}</Name>
          <Text>{marker.address1}</Text>
          <Text>{marker.address2}</Text>
          <Text>{marker.postCode}</Text>
          <Text><a href="tel:marker.phoneNumber">{marker.telephone}</a></Text>
          <Text><a href="mailto:{marker.emailAddress}">{marker.emailAddress}</a></Text>
          {marker.distance && <Distance>{marker.distance} miles away</Distance>}
          <HoursBox reducedPadding={marker.distance}>
            <OpenHours>
              <Day>Mon-Sat:</Day>
              <Text>{marker.openingHourWeek}</Text>
            </OpenHours>
            <OpenHours>
              <Day>Sun:</Day>
              <Text>{marker.openingHourSun}</Text>
            </OpenHours>
          </HoursBox>
          <Square/>
        </InfoBox>
      }
    </div>
}

export default MapMarker;
