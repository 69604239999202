import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import MapMarker from "./Marker"

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 52.824887,
      lng: -4.531971
    },
    zoom: 14.5,
  };

  state = {
    nearest: null
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.place !== null) {
      const { place: origin, radius, setToggledMarker, setCenter } = this.props;
      const originLat =  origin.geometry.location.lat()
      const originLng =  origin.geometry.location.lng()
      this.markers.forEach((marker) => {
        let distanceFromOrigin = this.distance(marker.location.lat, marker.location.lon, originLat, originLng)
        marker.distance = Math.round(distanceFromOrigin * 100) / 100
      })
      const nearest = this.markers.reduce((acc, marker) => {
        let prev = this.distance(acc?.location?.lat, acc?.location?.lon, originLat, originLng)
        let current = this.distance(marker.location.lat, marker.location.lon, originLat, originLng)
        let result = prev < current ? acc : marker;
        if (radius) {
          if (radius >= result.distance) {
            return result
          } else {
            return null
          }
        }
        if (!radius) {
          return result
        }
      })

      if (prevState.nearest !== nearest) {
        if (nearest) {
          this.props.updateNearest(nearest)
          this.setState({nearest:nearest})
          setToggledMarker(nearest.id)
          setCenter(nearest)
        }
        if (!nearest) {
          this.props.updateNearest(null)
          this.setState({nearest:null})
          setToggledMarker(null)
        }
      }
    }
  }


  distance(lat1, lon1, lat2, lon2) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      const radlat1 = Math.PI * lat1/180;
      const radlat2 = Math.PI * lat2/180;
      const theta = lon1-lon2;
      const radtheta = Math.PI * theta/180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      return dist;
    }
  }

  createMapOptions = function (maps) {
    return {
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#222e3c"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#2f4d59"
            }
          ]
        },
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#406d80"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#406d80"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#406d80"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#406d80"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#37424f"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#000914"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
  ],
      fullScreenControl: false
    }
  };

  markers = this.props.locations

  render() {
    const {apiHasLoaded, setToggledMarker, toggledMarker, newCenter} = this.props;

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100%', width: '100%', position: 'absolute', zIndex: '1' }} id={"map"}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAtzQQA34pRx-Z_LtARn55UZqNREwdhblA', libraries: ['places', 'geometry'], }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={this.createMapOptions}
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
          center={newCenter && {lat: newCenter.location.lat, lng: newCenter.location.lon}}
        >
          {this.markers.map((marker, index) => {
            const { location } = marker;
            return (
              <MapMarker
                lat={location.lat}
                lng={location.lon}
                key={index}
                toggle={setToggledMarker}
                isToggled={marker.id === toggledMarker}
                marker={marker}
              />
            )
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
