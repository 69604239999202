import React from "react"
import styled from "styled-components"
import { Row, Container } from "styled-bootstrap-grid"
import fonts from "../../styles/fonts"
import ShipmatesCarousel from "./Carousel"
import BackgroundImgMobile from "../../resources/images/shipmates/carousel-background-mobile.jpg"
import BackgroundImg from "../../resources/images/shipmates/carousel-background.jpg"

const IntroBlackBackground = styled.div`
  width: 100%;
  height: 100%;
  background: #000914;
  padding-left: 0;
  padding-right: 0;
`

const ContentBackground = styled.div`
  position: relative;
  background: url(${BackgroundImgMobile}) no-repeat;
  background-position: 50%;
  background-size: cover;
  @media (min-width: 678px) {
    width: 100vw;
    background: url(${BackgroundImg}) no-repeat;
    background-position: 50%;
    background-size: contain;
  }
`

const ContentContainer = styled(Container)`
  padding: 30px 0 0 0;
  @media (min-width: 768px) {
    padding-top: 55px;
    padding-left: 0;
    padding-right: 0;
  }
`

const StyledRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
  padding-left: 33px;
  padding-right: 33px;
  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const IntroText = styled.div`
  color: #9ca8b5;
  font-size: 15px;
  line-height: 21px;
  ${fonts.gilroyRegular};
  max-width: 553px;
  text-align: center;
  margin: auto auto 100px auto;
`

const CarouselContainer = styled(Container)`
  padding: 0;
  margin: auto;
  width: auto;
`

const ContentBlock = props => {
  return (
    <IntroBlackBackground>
      <ContentBackground>
        <ContentContainer>
          <StyledRow>
            <IntroText>
              Say ahoy to our shipmates ~ We supply only a limited number of trade customers so we can really support
              companies who share our vision and passion for the British coast.
            </IntroText>
          </StyledRow>
        </ContentContainer>
        <CarouselContainer fluid>
          <ShipmatesCarousel {...props} />
        </CarouselContainer>
      </ContentBackground>
    </IntroBlackBackground>
  )
}

export default ContentBlock
