import React from "react"
import styled from "styled-components"
import { Fade } from "react-reveal"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Line from "../core/Line"
import ShortOverlayBottom from "../core/ShortOverlayBottom"
import fonts from "../../styles/fonts"

const Background = styled.div`
    height: 430px;
    background: #020913;
    z-index: -11;
    @media(max-width: 768px) {
        height: 350px;
    }
`

const BackgroundStyled = styled.div`
    position: absolute;
    height: 420px;
    margin-top: -138px;
    text-align: center;
    background: #020913;
    @media(min-width: 992px) {
        height: 460px;
        margin-top: -98px;
    }
`;

const BackgroundImageStyled = styled(BackgroundImage)`
    width: 100vw;
    height: 100%;
    background-size: cover;
    max-height: 500px;
`;

const TitleBlock = styled.div`
    text-align: center;
    padding-top: 60px;
    @media(min-width: 992px) {
        padding-top: 100px;
    }
`;

const Title = styled.h1`
    text-transform: uppercase;
    color: white;
    letter-spacing: 2.2px;
    margin-bottom: 0;
    ${fonts.swissBlackExtended};
    font-size: 34px;
    line-height: 46px;
    cursor: default;
    @media (min-width: 586px) {
        font-size: 65px;
    }
    @media (min-width: 768px) {
        font-size: 78px;
        line-height: 87px;
        letter-spacing: 5px;
    }  
`;

const Hero = () => (
  <StaticQuery query={graphql`
      query {
        background: file(relativePath: { eq: "shipmates/hero-background.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 5000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
     render={data => {
       const imageData = data.background.childImageSharp.fluid

       return (
         <Background>
           <BackgroundStyled>
             <BackgroundImageStyled fluid={imageData} critical={true} durationFadeIn={200} />
             <ShortOverlayBottom/>
           </BackgroundStyled>
           <TitleBlock>
             <Fade duration={750} delay={500}>
               <Title>Shipmates</Title>
             </Fade>
             <Fade bottom duration={750} delay={500}>
               <Line/>
             </Fade>
           </TitleBlock>
         </Background>
       )
     }
   }
  />)

export default Hero;
